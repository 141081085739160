import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import Login from "./routes/login";
import Tasks from "./routes/tasks";
import Callback from "./routes/callback";
import FarmSetup from "./routes/farm-setup";
import ProfileNotifications from "./components/profile/ProfileNotifications";
import ViewProfile from "./components/profile/ViewProfile";
import LayoutProfile from "./components/profile/LayoutProfile";
import ProfileLanguages from "./components/profile/ProfileLanguages";
import ErrorPage from "./error-page";
import Root from "./routes/root";
import "./index.scss";
import { ThemeContextProvider } from "./useContext/context";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/profile/*",
        element: <LayoutProfile />
      },
      {
        path: "/view-profile",
        element: <ViewProfile />
      },
      {
        path: "/profile-notifications",
        element: <ProfileNotifications />
      },
      {
        path: "profile-languages",
        element: <ProfileLanguages />
      }
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/farm-setup",
    element: <FarmSetup />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <RouterProvider router={router} />
    </ThemeContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
