import { useState } from "react";
import styles from "../styles/routes/Filtercomponent.module.scss";

interface FilterComponentProps {
  options: string[];
  selectedFilters: string[];
  onFilterChange: (filter: string[]) => void;
  onClearFilters: () => void;
}

export default function FilterComponent({
  options,
  selectedFilters,
  onFilterChange,
  onClearFilters,
}: FilterComponentProps) {
  const handleFilterChange = (filter: string) => {
    if (filter === "All") {
      if (selectedFilters.includes("All")) {
        onFilterChange([]);
      } else {
        onFilterChange(["All"]);
      }
    } else {
      const newFilters = selectedFilters.includes(filter)
        ? selectedFilters.filter((f) => f !== filter)
        : [...selectedFilters, filter];
      onFilterChange(newFilters);
    }
  };

  return (
    <div className={styles.filterComponent}>
      {options.map((option) => (
        <label
          key={option}
          className={styles["task-list__checkbox"]}
          style={{ fontSize: "1.5rem", fontWeight: "400" }}
        >
          <input
            id={`filter-${option}`}
            name={`filter-${option}`}
            type="checkbox"
            checked={selectedFilters.includes(option)}
            onChange={() => handleFilterChange(option)}
            aria-label={`Filter by ${option}`}
          />
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" rx="4" />
            <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
          </svg>
          {option}
        </label>
      ))}
      <button
        className={styles.clearFiltersButton}
        onClick={onClearFilters}
        style={{ marginTop: "10px", cursor: "pointer" }}
      >
        Remove All Filters
      </button>
    </div>
  );
}
