import styles from "../../styles/routes/FarmSetup.module.scss";
import type { SetupProps } from "../../types";


type TGetStartedProps = SetupProps;

const GetStarted: React.FC<TGetStartedProps> = ({ page, changePage }) => {

    return (
        <div className={styles["main__section-starting"]}>
            <div style={{"width": "100%"}}>
                <h2>Let's start</h2>
                <h2 style={{
                    "fontSize": "2.7rem",
                    "fontWeight": "300"
                }}>Setting Up Your Farm</h2>
                <p>Hi Sam, let's start working on your farm settings.</p>
                <button
                    onClick={() => changePage({page, isForward:true})}
                    className="form__button"
                >
                    Get Started
                </button>
            </div>
        </div>

    )
};

export default GetStarted;

