import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import homeIcon from "../assets/icons/home.svg";
import profileIcon from "../assets/icons/profile.svg";
import filesIcon from "../assets/icons/files.svg";
import starIcon from "../assets/icons/star.svg";

import styles from "../styles/Header.module.scss";

export default function Header() {
  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <NavLink className={styles.nav__logo} to="/">
            <img src={logo} alt="Farmers App" />
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/"
          >
            <img src={homeIcon} alt="Home" />
            <span>Home</span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/tasks"
          >
            <img src={filesIcon} alt="files" />
            <span>Tasks</span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/inputs"
          >
            <img src={starIcon} alt="Home" />
            <span>Inputs</span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/profile"
          >
            <img src={profileIcon} alt="files" />
            <span>Profile</span>
          </NavLink>
        </nav>
      </header>
    </>
  );
}
