import { useState } from "react"
import ProfileHeading from "./ProfileHeading";
import CustomRadioButton from "./CustomRadioButton";
import styles from "../../components/profile/ProfileLanguages.module.scss";
import language from "../../assets/icons/profile/language.svg";

const ProfileLanguages: React.FC = () => {
    const [selectedLanguage, setselectedLanguage] = useState("US");

    const onLanguageChange = (value: string) => {
        setselectedLanguage(value);
    };



    return (
        <div className={styles["profile-languages"]}>
            <ProfileHeading
                pageTitle="Language"
                prevPage="/profile/profile-notifications"
                nextPage="/profile/profile-languages"
            />
            <div className={styles["profile-languages__suggested"]}>
                <h2 className={styles["profile-languages__title"]}> SUGGESTIONS </h2>
                <ul className={styles["profile-languages__suggested__items"]}>
                    <li>
                        <CustomRadioButton
                            img={language}
                            label="English (US)"
                            checked={selectedLanguage === 'US'}
                            onChange={() => onLanguageChange('US')}
                        />
                    </li>
                    <li>
                        <CustomRadioButton
                            img={language}
                            label="English (UK)"
                            checked={selectedLanguage === 'UK'}
                            onChange={() => onLanguageChange('UK')}
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProfileLanguages;
