interface CustomRadioButtonProps {
    img: any,
    label: string;
    checked: boolean;
    onChange: () => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({ img, label, checked, onChange }) => {
    return (
        <label>
            <input type="radio" checked={checked} onChange={onChange} />
            <span style={{
                display:"flex"
            }}>
                <img src={img} alt={img} />
                <p>{label}</p>
            </span>
        </label>
    );
};

export default CustomRadioButton;