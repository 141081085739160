import styles from "./Pagination.module.scss";

interface paginationProps {
  currentPage: number;
  totalPages: number;
  onNext: () => void;
  onPrevious: () => void;
}

export default function Pagination({
  currentPage,
  totalPages,
  onNext,
  onPrevious,
}: paginationProps) {
  return (
    <>
      <div className={styles.pagination}>
        <button
          className={`${styles.pagination__button} ${
            currentPage === 1 ? styles["pagination__button--disabled"] : ""
          }`}
          type="button"
          onClick={onPrevious}
          disabled={currentPage === 1}
        >
          <svg
            width="10"
            height="17"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91382 1.50101L1.88631 8.48659L8.91382 15.4722"
              stroke="#0D1814"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <span className={styles.pagination__label}>
          Page {currentPage} of {totalPages}
        </span>

        <button
          className={`${styles.pagination__button} ${
            currentPage === totalPages
              ? styles["pagination__button--disabled"]
              : ""
          }`}
          type="button"
          onClick={onNext}
          disabled={currentPage === totalPages}
        >
          {" "}
          <svg
            width="10"
            height="17"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.78666 15.4838L8.79104 8.47501L1.74048 1.5127"
              stroke="#0D1814"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
