import { Link } from "react-router-dom";
import styles from "../../styles/SectionHeading.module.scss";

import linkIcon from "../../assets/icons/seeAllArr.svg";
import filterIcon from "../../assets/icons/filterIcon.svg";

export default function SectionHeading({
  title,
  label,
  action,
  icon,
  filterAction,
  filterCount = 0,
}: {
  title: string;
  label?: string;
  action?: () => void;
  icon?: string;
  filterAction?: () => void;
  filterCount?: number;
}) {
  return (
    <>
      <div className={styles.heading}>
        <h2 className={styles.heading__title}>
          {icon && (
            <img className={styles.heading__icon} src={icon} alt={title} />
          )}{" "}
          {title} {label && <span>{label}</span>}
        </h2>
        {filterAction && (
          <button className={styles.heading__filter} onClick={filterAction}>
            <span>Filter</span>
            {filterCount > 0 && (
              <span className={styles.heading__number}>{filterCount}</span>
            )}
            <img src={filterIcon} alt="Filter Icon" />
          </button>
        )}
        {action && (
          <button className={styles.heading__link} onClick={action}>
            <span>See All</span>
            <img src={linkIcon} alt="See All" />
          </button>
        )}
      </div>
    </>
  );
}
