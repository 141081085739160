import { useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./ProfileSettings.module.scss";
import ToggleNotifications from "./ToggleNotifications";
import notification from "../../assets/icons/notification/notification.svg";
import language from "../../assets/icons/profile/language.svg";
import help from "../../assets/icons/profile/help.svg";
import contact from "../../assets/icons/profile/contact-us.svg";
import privacy from "../../assets/icons/profile/privacy.svg";
import profile from "../../assets/icons/profile/profile.svg"



export default function ProfileSettings() {
    const [isActive, setIsActive] = useState(null);

    const handleClick = (index: any) => {
        setIsActive(index);
    };


    return (
        <>
            <div className={styles["profile"]}>
                <h2 className={styles["profile__heading"]}>
                    PROFILE SETTNGS
                </h2>
                <div className={styles["profile__list"]}>
                    <NavLink onClick={() => handleClick(0)} className={`${styles["profile__list__item"]} ${isActive === 0 ? styles["profile__list__item--is-active"] : ''}`} to='view-profile'>
                        <img src={profile} alt="Profile" />
                        Profile
                    </NavLink>
                    <NavLink onClick={() => handleClick(1)} className={`${styles["profile__list__item"]} ${isActive === 1 ? styles["profile__list__item--is-active"] : ''}`} to='profile-notifications'>
                        <img src={notification} alt="Notification" />
                        Notification
                        <ToggleNotifications />
                    </NavLink>
                    <NavLink onClick={() => handleClick(2)} className={`${styles["profile__list__item"]} ${isActive === 2 ? styles["profile__list__item--is-active"] : ''}`} to='profile-languages'>
                        <img src={language} alt="Language" />
                        Language
                        <span style={{
                            "color": "#427662",
                            "paddingLeft": "4rem"
                        }}>English</span>
                    </NavLink>
                </div>
            </div>
            <div className={styles["profile"]}>
                <h2 className={styles["profile__heading"]}>
                    GENERAL SETTNGS
                </h2>
                <ul className={styles["profile__list"]}>
                    <li onClick={() => handleClick(3)}
                        className={`${styles["profile__list__item"]} ${isActive === 3 ? styles["profile__list__item--is-active"] : ''}`}>
                        <img src={help} alt="Help" />
                        Help & Support
                    </li>
                    <li onClick={() => handleClick(4)}
                        className={`${styles["profile__list__item"]} ${isActive === 4 ? styles["profile__list__item--is-active"] : ''}`}>
                        <img src={contact} alt="Contact" />
                        Contact Us
                    </li>
                    <li onClick={() => handleClick(5)}
                        className={`${styles["profile__list__item"]} ${isActive === 5 ? styles["profile__list__item--is-active"] : ''}`}>
                        <img src={privacy} alt="Privacy" />
                        Privacy Policy
                    </li>
                </ul>
            </div>
            <a href="#" style={{
                "color": "#427662",
                "position": "absolute",
                "bottom": "0",
                "fontSize": "1.4rem",
                "left": "0"
            }}
            >
                Log Out
            </a>
        </>
    )
}
