import Map from "../map/Map";
import MapStateLabel from "../map/MapStateLabel";
import L from "leaflet";

import styles from "../../styles/FieldCardSm.module.scss";

export default function FieldCardSm({
  label,
  subtitle,
  area,
  markers,
  zoomControl = false,
}: {
  label: string;
  subtitle: string;
  area?:
    | L.LatLngExpression[]
    | L.LatLngExpression[][]
    | L.LatLngExpression[][][];
  markers?: Array<L.LatLngExpression>;
  zoomControl?: boolean;
}) {
  return (
    <>
      <div className={styles.field}>
        <div className={styles.field__media}>
          <Map area={area} markers={markers} zoomControl={zoomControl} />
        </div>
        <div className={styles.field__heading}>
          <span className={styles.field__title}>{label}</span>
          <span className={styles.field__subtitle}>{subtitle}</span>
        </div>
      </div>
    </>
  );
}
