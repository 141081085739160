import { useState } from 'react';
import styles from "../../components/profile/ToggleNotification.module.scss"

interface ToggleSwitchProps {
  label?: string;
}

const ToggleNotifications: React.FC<ToggleSwitchProps> = ({ label }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <label className={styles.toggleSwitch}>
      {label && <span className={styles.label}>{label}</span>}
      <input 
        type="checkbox" 
        checked={checked} 
        onChange={handleChange} 
        className={styles.input} 
      />
      <div className={styles.switch} />
    </label>
  );
};

export default ToggleNotifications;
