import { NavLink } from "react-router-dom";
import styles from "../../styles/FarmSetupHeading.module.scss";
import arrLeft from "../../assets/icons/arrLeft.svg";
import arrRight from "../../assets/icons/arrRight.svg";

export default function ProfileHeading({
    pageTitle,
    prevPage,
    nextPage 
}: {
    pageTitle: string,
    prevPage: any,
    nextPage: any
}) {
    return (
        <div className={styles["setup-heading"]}
            style={{
                "justifyContent": "space-between",
                "marginBottom": "5rem"
            }}>
            <NavLink className={styles.nav__logo} to={prevPage}>
                <img
                    src={arrLeft}
                    alt="Arr Left"
                />
            </NavLink>
            <h2 className={styles["setup-heading__heading"]}>{pageTitle}</h2>
            <NavLink className={styles.nav__logo} to={nextPage}>
                <img
                    src={arrRight}
                    alt="Arr Right"
                />
            </NavLink>
        </div>
    )
}