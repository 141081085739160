import ProfileHeading from "./ProfileHeading";
import ToggleNotifications from "./ToggleNotifications";
import styles from "../../components/profile/ProfileNotifications.module.scss";
import notification from "../../assets/icons/profile/notification-gray.svg";

export default function ProfileNotifications() {
    return (
        <div className={styles["profile-notifications"]}>
            <ProfileHeading
                pageTitle="Notifications"
                prevPage="/profile/view-profile"
                nextPage="/profile/profile-languages"
            />
            <div className={styles["profile-notifications__tasks"]}>
                <h2 className={styles["profile-notifications__title"]}> TASKS </h2>
                <ul className={styles["profile-notifications__tasks__items"]}>
                    <li>
                        <img src={notification} alt="Notification Icon" />
                        Overdue Tasks
                        <ToggleNotifications />
                    </li>
                    <li>
                        <img src={notification} alt="Notification Icon" />
                        Canceled Tasks
                        <ToggleNotifications />
                    </li>
                    <li>
                        <img src={notification} alt="Notification Icon" />
                        Canceled Tasks
                        <ToggleNotifications />
                    </li>
                    <li>
                        <img src={notification} alt="Notification Icon" />
                        Upcoming Tasks
                        <ToggleNotifications />
                    </li>
                </ul>
            </div>
        </div>
    )
}