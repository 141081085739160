import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SectionWrapper from "./components/section/SectionWrapper";
import SectionHeading from "./components/section/SectionHeading";
import Weather from "./components/weather/Weather";
import TaskList from "./components/list/TaskList";
import Map from "./components/map/Map";
import LocationLabel from "./components/location/LocationLabel";
import Slider from "./components/slider/Slider";
import FieldCardSm from "./components/card/FieldCardSm";
import Heading from "./components/page/Heading";
import Modal from "./components/modal/Modal";
import NewTaskForm from "./components/form/NewTaskForm";
import Pagination from "./components/pagination/Pagination";
import { useFetch } from "./hooks/useFetch";
import { ThemeContext } from "./useContext/context";
import AddNewField from "./components/setupFarm/AddNewField";

import homePageIcon from "./assets/icons/home.svg";

import styles from "./styles/routes/home.module.scss";
import FilterComponent from "./components/FilterComponent";

type FilterType = "all" | "inProgress" | "completed" | "overdue" | "upcoming";

const startIndex = 0;
const endIndex = 3;

interface Field {
  id: string;
  title: string;
  subtitle: string;
  area: L.LatLngExpression[];
  markers: L.LatLngExpression[];
}

const fieldsData: Field[] = [
  {
    id: "field1",
    title: "Grapes F1",
    subtitle: "1.6 Ha",
    area: [
      [45.10945030797108, 19.884384990622966],
      [46.10945030797108, 23.884384990622966],
      [47.10945030797108, 21.884384990622966],
      [51.10945030797108, 25.884384990622966],
    ] as L.LatLngExpression[],
    markers: [
      [45.10945030797108, 19.884384990622966],
      [46.10945030797108, 19.884384990622966],
    ] as L.LatLngExpression[],
  },
  {
    id: "field2",
    title: "Corn Field A",
    subtitle: "2.3 Ha",
    area: [
      [40.10945030797108, 15.884384990622966],
      [41.10945030797108, 20.884384990622966],
      [42.10945030797108, 18.884384990622966],
      [44.10945030797108, 22.884384990622966],
    ] as L.LatLngExpression[],
    markers: [
      [40.10945030797108, 15.884384990622966],
      [41.10945030797108, 15.884384990622966],
    ] as L.LatLngExpression[],
  },
  {
    id: "field3",
    title: "Rice Field B",
    subtitle: "5.1 Ha",
    area: [
      [43.10945030797108, 19.884384990622966],
      [44.10945030797108, 22.884384990622966],
      [45.10945030797108, 20.884384990622966],
      [46.10945030797108, 23.884384990622966],
    ] as L.LatLngExpression[],
    markers: [
      [43.10945030797108, 19.884384990622966],
      [44.10945030797108, 20.884384990622966],
    ] as L.LatLngExpression[],
  },
  {
    id: "field4",
    title: "Tomato Field C",
    subtitle: "0.9 Ha",
    area: [
      [47.10945030797108, 18.884384990622966],
      [48.10945030797108, 22.884384990622966],
      [49.10945030797108, 20.884384990622966],
      [50.10945030797108, 24.884384990622966],
    ] as L.LatLngExpression[],
    markers: [
      [47.10945030797108, 18.884384990622966],
      [48.10945030797108, 18.884384990622966],
    ] as L.LatLngExpression[],
  },
  {
    id: "field5",
    title: "Wheat Field D",
    subtitle: "3.5 Ha",
    area: [
      [50.10945030797108, 17.884384990622966],
      [51.10945030797108, 21.884384990622966],
      [52.10945030797108, 19.884384990622966],
      [53.10945030797108, 23.884384990622966],
    ] as L.LatLngExpression[],
    markers: [
      [50.10945030797108, 17.884384990622966],
      [51.10945030797108, 17.884384990622966],
    ] as L.LatLngExpression[],
  },
];

const FirstColContent = memo(function FirstColContent({
  setIsTasksModalOpen,
}: {
  setIsTasksModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const [filters, setFilters] = useState<string[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState<string[]>(filters);

  const handleFilterChange = (newFilters: string[]) => {
    setTempFilters(newFilters);
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters);
    setIsFilterModalOpen(false);
  };

  return (
    <>
      <SectionWrapper
        customClassName={styles.main__weather__section}
        customStyle={{
          border: 0,
        }}
      >
        <Weather />
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeading
          title="Tasks To Do"
          filterAction={() => setIsFilterModalOpen(true)}
          action={() => setIsTasksModalOpen!(true)}
          filterCount={filters.length}
        />
        <TaskList filter={filters} />
      </SectionWrapper>

      <Modal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        headingTitle="Filter Tasks"
        actionButtonTitle="Apply"
        actionButtonFn={handleApplyFilters}
      >
        <FilterComponent
          options={["All", "inProgress", "completed", "overdue", "upcoming"]}
          selectedFilters={tempFilters}
          onFilterChange={handleFilterChange}
          onClearFilters={() => setTempFilters([])}
        />
      </Modal>
    </>
  );
});

const SecondColContent = memo(function SecondColContent({
  setIsMyFieldsModalOpen,
}: {
  setIsMyFieldsModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <SectionWrapper customClassName={styles.main__fields__section}>
        <SectionHeading
          title="My Fields"
          action={() => setIsMyFieldsModalOpen!(true)}
        />
        <div className={styles.main__fields}>
          <Slider customClassName={styles.main__fields__slider}>
            {fieldsData.slice(startIndex, endIndex).map((field) => (
              <div
                key={field.id}
                className={`${styles.main__fields__field} ${styles.field}`}
              >
                <div className={styles.field__media}>
                  <Map area={field.area} markers={field.markers} />
                </div>
                <div className={styles.field__heading}>
                  <span className={styles.field__title}>{field.title}</span>
                  <span className={styles.field__subtitle}>
                    {field.subtitle}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </SectionWrapper>
      <SectionWrapper
        customClassName={styles.main__map__section}
        customStyle={{ flex: 1, padding: 0, border: 0 }}
      >
        <Map
          markers={[
            [45.10945030797108, 19.884384990622966],
            [46.10945030797108, 19.884384990622966],
          ]}
          zoomControl={true}
        />
        <LocationLabel
          locationName="Giri Purno"
          customStyle={{
            position: "absolute",
            top: "1.5rem",
            left: "2rem",
          }}
        />
      </SectionWrapper>
    </>
  );
});

function App() {
  const context = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);

  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isMyFieldsModalOpen, setIsMyFieldsModalOpen] = useState(false);
  const [isNewFieldModalOpen, setIsNewFieldModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("addFieldPage");
  const [paginationPage, setPaginationPage] = useState(1);

  const FieldsPerPage = 2;

  const fieldsDisplayed = fieldsData.slice(
    (paginationPage - 1) * FieldsPerPage,
    paginationPage * FieldsPerPage
  );

  const totalPages = Math.ceil(fieldsData.length / FieldsPerPage);

  const handlePaginationChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setPaginationPage(page);
    }
  };

  const nextPage = () => {
    if (paginationPage < totalPages) {
      setPaginationPage(paginationPage + 1);
    }
  };

  const previousPage = () => {
    if (paginationPage > 1) {
      setPaginationPage(paginationPage - 1);
    }
  };

  const observer = new ResizeObserver(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  const handlePageChange = ({
    page,
    isForward,
  }: {
    page?: string;
    isForward?: boolean;
  }) => {
    if (isForward) setCurrentPage(page || "nextPage");
    else setCurrentPage(page || "previousPage");
  };

  const handleNewFieldClick = () => {
    setIsNewFieldModalOpen(true);
  };

  useEffect(() => {
    observer.observe(document.body);

    // useFetch({
    //   path: "/Areas?$top=2&$skip=0&$count=true",
    //   region: "eu10-004",
    //   service: "catalog",
    // });

    // console.log(context?.state.user.accessToken);
  }, []);

  return (
    <>
      <main className={styles.main}>
        <Heading
          pageTitle="Home"
          pageIcon={homePageIcon}
          actionTitle="New Task"
          action={() => setIsNewTaskModalOpen(true)}
        />
        {isMobile ? (
          <>
            <FirstColContent
              setIsTasksModalOpen={() => setIsTasksModalOpen(true)}
            />
            <SecondColContent setIsMyFieldsModalOpen={setIsMyFieldsModalOpen} />
          </>
        ) : (
          <>
            <div className={styles["main__first-col"]}>
              <FirstColContent setIsTasksModalOpen={setIsTasksModalOpen} />
            </div>
            <div className={styles["main__second-col"]}>
              <SecondColContent
                setIsMyFieldsModalOpen={setIsMyFieldsModalOpen}
              />
            </div>
          </>
        )}
      </main>

      <Modal
        headingTitle="My Fields"
        headingSubtitle="Here is a complete list of your Fields"
        actionButtonTitle="New Field"
        actionButtonFn={handleNewFieldClick}
        isOpen={isMyFieldsModalOpen}
        onClose={() => setIsMyFieldsModalOpen(false)}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(23rem, 1fr))",
            gap: "var(--form-gr-mb)",
          }}
        >
          {fieldsDisplayed.map((field) => (
            <FieldCardSm
              key={field.id}
              label={field.title}
              subtitle={field.subtitle}
              area={field.area}
              markers={field.markers}
            />
          ))}
        </div>
        {fieldsData.length > FieldsPerPage && (
          <Pagination
            currentPage={paginationPage}
            totalPages={totalPages}
            onNext={nextPage}
            onPrevious={previousPage}
          />
        )}
      </Modal>

      <Modal
        headingTitle="Add New Field"
        headingSubtitle="Add a new field to your farm"
        isOpen={isNewFieldModalOpen}
        onClose={() => setIsNewFieldModalOpen(false)}
      >
        <AddNewField page={currentPage} changePage={handlePageChange} />
      </Modal>

      <Modal
        headingTitle="Tasks Overdue"
        isOpen={isTasksModalOpen}
        onClose={() => setIsTasksModalOpen(false)}
      >
        <TaskList showDate={true} isOverdue={true} />
      </Modal>

      <Modal
        headingTitle="Create New Task"
        headingIcon={homePageIcon}
        isOpen={isNewTaskModalOpen}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm />
      </Modal>
    </>
  );
}

export default App;
