import styles from "../../styles/SuccessfuImport.module.scss";
import Successful from "../../assets/icons/successful.svg";
import type { SetupProps } from "../../types";
type TSuccessfulImport = SetupProps;

const SuccesfulImport: React.FC<TSuccessfulImport> = ({ page, changePage }) => {

    return (
        <div className={styles["successful-import"]}>
            <div className={styles["successful-import__heading"]}>
                <h2>Field Import was <span style={{ color: "#427662" }}>Successful</span></h2>
                <p>You can later also add fields to your farm</p>
            </div>
            <div className={styles["successful-import__body"]}>
                <span className={styles["successful-import__body__item"]}>
                    <img src={Successful} alt="successful" /> Ausleben Nord
                </span>
                <span className={styles["successful-import__body__item"]}>
                    <img src={Successful} alt="successful" />
                    <span>Ausleben Hoetensleben</span>
                </span>
                <span className={styles["successful-import__body__item"]}>
                    <img src={Successful} alt="successful" /> Ausleben-Waesleben West
                </span>
                <button
                    className="form__button"
                >
                    Access Your Farm
                </button>
            </div>
        </div>
    )

};

export default SuccesfulImport;
