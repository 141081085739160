
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProfileSettings from "./Settings";
import Heading from "../page/Heading";
import styles from "../../styles/routes/profile.module.scss";
import Area from "../../assets/area.png";
import ViewProfile from "./ViewProfile";
import ProfileNotifications from "./ProfileNotifications";
import ProfileLanguages from "./ProfileLanguages";

export default function LayoutProfile() {
    return (
        <main className={styles.main}>
            <Heading
                pageTitle="Settings"
            />
            <div className={styles["main__body"]}>
                <div className={styles["main__body__sidebar"]}>
                    <img src={Area} />
                    <h1>Samuela Farmer</h1>
                    <h2> FARM MANAGER</h2>
                    <ProfileSettings />
                </div>
                <Routes>
                    <Route path="view-profile" element={<ViewProfile />} />
                    <Route path="profile-notifications" element={<ProfileNotifications />} />
                    <Route path="profile-languages" element={<ProfileLanguages />} />
                </Routes>
            </div>
        </main>
    )
}