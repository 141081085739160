import styles from "../../styles/FarmSetupHeading.module.scss";
import arrLeft from "../../assets/icons/arrLeft.svg";
import type { SetupProps } from "../../types";


type TFarmInfoProps = SetupProps;

const SetupHeading: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
    return (
        <div className={styles["setup-heading"]}>
            <img
                src={arrLeft}
                alt="Arr Left"
                onClick={() => changePage({page, isForward:false})}
            />
            <span>
                <h2 className={styles["setup-heading__heading"]}>Check Your Field Information</h2>
                <p className={styles["setup-heading__description"]}>Select the fields you would like to import</p>
            </span>
        </div>
    )
}

export default SetupHeading;
