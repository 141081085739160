import ProfileHeading from "./ProfileHeading";
import styles from "./ViewProfile.module.scss";


const ViewProfile: React.FC = () => {
    return (
        <div className={styles["view-profile"]}>
            <ProfileHeading 
                pageTitle="Profile"
                prevPage="/profile/view-profile"
                nextPage="/profile/profile-notifications"
            />
            <form action="" className={styles["view-profile__form"]}>
                <div className="form__input">
                    <label>
                        NAME *
                    </label>
                    <input type="text" name="name" placeholder="Samuela" disabled />
                </div>
                <div className="form__input">
                    <label>
                        LAST NAME *
                    </label>
                    <input type="text" name="last_name" placeholder="Farmer" disabled />
                </div>
                <div className="form__input">
                    <label>
                        FARM *
                    </label>
                    <input type="text" name="farm" placeholder="Giri Purno" disabled />
                </div>
                <div className="form__input">
                    <label>
                        ROLE *
                    </label>
                    <input type="text" name="role" placeholder="Farm Manager" disabled />
                </div>
                <div className="form__input">
                    <label>
                        EMAIL *
                    </label>
                    <input type="text" name="email" placeholder="samuela@gmail.com" disabled />
                </div>
                <div className="form__input">
                    <label>
                        PHONE NUMBER *
                    </label>
                    <input type="number" name="phone_number" placeholder="665464646" disabled />
                </div>
                <div className="form__input">
                    <label>
                        COUNTRY *
                    </label>
                    <input type="text" name="country" placeholder="Germany" disabled />
                </div>
                <div className="form__input">
                    <label>
                        ADDRESS *
                    </label>
                    <input type="text" name="address" placeholder="26373 Graben" disabled />
                </div>
            </form>
        </div>
    )
}

export default ViewProfile;

